.section-header__container__reporting-achievement {
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .left-side {
        display: flex;
        align-items: center;

        .title {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
        }
    }

    .showing-text {
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        display: flex;
        align-items: center;
        text-align: center;
        color: #5c6c8b;

        span {
            font-weight: 600;
            margin-left: 4px;
        }
    }

    .react-datepicker-wrapper {
        border: 1px solid #b0b7bf;
        border-radius: 4px;
        cursor: pointer;
    }

    .session-form {
        color: black;


        .react-datepicker__view-calendar-icon {
            input {
                padding: 6px 8px 6px 12px
            }
        }

        .react-datepicker__input-container {
            display: flex;
            flex-direction: row-reverse;
            outline: none;

            input {
                font-size: 13px;
                width: 100px;
                font-weight: 500;
            }

            svg {
                padding-top: 4px;
                margin-top: 2px;
                margin-right: 12px;
            }
        }

    }
}

.form-field__container {
    margin-bottom: 16px;

    .label {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #2d2d2d;
        margin-bottom: 8px;
        height: 24px;
        text-transform: capitalize;

        @media (max-width: 767px) {
            font-size: 11px;
            margin-bottom: 4px;
        }
    }

    .react-date-picker,
    .input-field,
    .camera-button,
    .input-time-picker-native__container {
        width: 100%;
        border: 1px solid rgb(165, 165, 165);
        box-sizing: border-box;
        height: 48px;
        padding: 0 16px;
        border-radius: 4px;
        background: white;
        flex: 1;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        outline: grey;
        color: #2d2d2d;

        @media (max-width: 767px) {
            height: 32px;
            font-size: 10px;
        }
    }

    .camera-button {
        display: flex;
        align-items: center;
        position: relative;

        .icon {
            position: absolute;
            right: 8px;
            top: 4px;
        }
    }

    .input-field::placeholder,
    .text-placeholder {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        @media (max-width: 767px) {
            font-size: 10px;
        }
    }

    .error-empty__field {
        border: 0.5px solid var(--error-color) !important;
    }

    .error-field__text {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: var(--error-color);
        margin-top: 4px;
    }

    // .react-date-picker-wrapper {
    //     border: none;
    //     cursor: pointer;

    //     input {
    //         outline: none;
    //         padding: 0 8px;
    //     }

    //     .react-date-picker-calendar-button {
    //         padding-right: 16px;
    //     }
    // }
}

.form-field__container_comment {
    margin-bottom: 16px;
    width: 100%;
    top: 0;
    position: sticky;
    background: white;

    .label {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #2d2d2d;
        margin-bottom: 8px;
        height: 24px;
    }

    .button {
        width: 100px;
        background: var(--main-color) !important;
        color: white;
        height: 32px;
        font-size: 10px;
        width: 100px;
    }

    .react-date-picker,
    .input-field {
        width: 100%;
        border: 1px solid rgb(165, 165, 165);
        box-sizing: border-box;
        height: 48px;
        padding: 0 16px;
        border-radius: 4px;
        background: white;
        flex: 1;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        outline: grey;
        color: #2d2d2d;
    }

    .input-field::placeholder {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #9c9898;
    }

    .error-empty__field {
        border: 0.5px solid var(--error-color) !important;
    }

    .error-field__text {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: var(--error-color);
        margin-top: 4px;
    }

    .react-date-picker-wrapper {
        border: 1px solid black;
        cursor: pointer;

        input {
            outline: none;
            padding: 0 8px;
        }

        // .react-date-picker-calendar-button {
        //     padding-right: 16px;
        // }
    }
}