.progress-container{
    border-radius: 8px;
    overflow: hidden;

    .bar{
        width: 100%;
        height: 100%;
        background: white;

        .value{
            height: 100%;
            animation-name: progressBar;
            animation-duration: 8s;
            width: 87%;
            overflow: hidden;
            border-radius: 32px;
        }

        .shimmer{
            width: 300px;
            animation-name: shimmer;
            animation-duration: 0.5s;
            background: rgb(222, 110, 110);
            height: 100%;
            animation-iteration-count: infinite;

        }
    }
}

@keyframes progressBar {
    from {
        width: 0%;
    }
    to {
        width: 87%;
    }
  }


  @keyframes shimmer {
    from {
        transform:translateX(0);
    }
    to {
        transform:translateX(1000px);
    }
  }