.section-target__container__mobile {
    .filter-month {
        border: 1px solid rgb(181, 181, 181);
        height: max-content;
        padding: 4px 8px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        position: relative;
        width: max-content;

        .divider {
            position: absolute;
            top: 4px;
            bottom: 4px;
            width: 1px;
            background: grey;
            left: 30px;
        }

        .filter-text {
            font-size: 11px;
            font-weight: 500;
            padding: 0 12px 0 16px;
            font-weight: 700;
            color: var(--main-color);
        }
    }

    .filter-month {
        border: 1px solid rgb(181, 181, 181);
        border-radius: 4px;
        height: max-content;
        width: 120px;

        .react-datepicker__input-container {
            width: max-content;
            display: flex;
            align-items: center;

            svg {
                height: 18px;
                width: 18px;
            }

            input {
                padding-left: 8px;
                font-size: 11px;
                font-weight: 500;
                padding: 0 12px 0 8px;
                font-weight: 700;
                color: var(--main-color);
                width: 100px;
                margin-left: 8px;
            }
        }
    }

    .target-container {
        margin-top: 24px;
        gap: 16px;
        flex-wrap: wrap;
        // justify-content: space-between;
        flex-direction: column;
        border: 1px solid #e1e1e1;
        gap: 4px;

        .percentage-text {
            font-size: 11px;
            font-weight: bold;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (min-width: 360px) {
                font-size: 12px;
            }
        }

        .item {
            flex: 1;
            padding: 12px 8px;
            border-radius: 6px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            height: max-content;
            .item-box {
                width: 40%;
                padding: 0 8px;
            }

            .CircularProgressbar {
                width: 46px;

                @media (min-width: 360px) {
                    width: 52px;
                }
            }
            .circular-target {
                width: 52px;
                @media (min-width: 360px) {
                    width: 52px;
                }
                position: relative;
                margin-right: 4px;
                padding: 0;
            }

            .title-label {
                font-size: 12px;
                color: black;
                font-weight: 600;
                margin-bottom: 4px;
            }

            .text-info {
                font-size: 11px;
                font-weight: 500;
                margin: 0;
                display: flex;
                align-items: center;

                span {
                    margin-right: 2px;
                }

                @media (max-width: 361px) {
                    font-size: 10px;
                }

                svg {
                    margin-right: 4px;
                    width: 16px;
                    height: 16px;
                }
            }

            .text-1 {
                font-size: 11px;
                margin-bottom: 6px;
            }

            .text-2 {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 6px;
            }

            .text-3 {
                font-size: 10px;
                margin-left: 2px;
                font-weight: 500;
                line-height: 24px;
            }

            .red-color {
                color: rgb(203, 88, 88);
            }
        }
    }
}
