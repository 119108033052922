.create-or-edit-event-popup__container{
    width: 480px;
    .header{
        padding: 16px;
        border-bottom: 1px solid grey;
        justify-content: space-between;

        .title{
            font-size: 14px;
            font-weight: 500;
        }
    }

    .body{
        padding: 16px;
        flex-direction: column;

        .text-area__container {
            height: 100px;
            padding-top: 16px;
        }
        //------------------------- TIME PICKER ------------------------------------------
        .time-picker__container{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .form-field__container{
                flex: 1;
            }

            .divider{
                margin: 0 16px;
                margin-top: 19px;
            }
        }
    }

    .footer{
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        padding: 24px 16px;

        button{
            background: var(--main-color);
            border-radius: 2px;
            cursor: pointer;
            height: 28px;
            padding: 7px 12px;
            width: 95px;


            color: #fff;
            font-family: Poppins;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
        }


        .button-cancel{
            background: none;
            border: 1px solid var(--main-color);
            color: var(--main-color);

        }
    }
}