.switch-container{
    .box-switch{
        display: flex;
        width: 50%;
        border: 1px solid var(--main-color);
        color: rgb(192, 187, 187);
        font-size: 14px;
        justify-content: center;
        align-items: center;
        height: 32px;
        cursor: pointer;
    }

    .box-1{
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .box-2{
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .active{
        background: var(--main-color);
        color: white;
    }
}