.section-body__container__reporting-achievement {
    margin-top: 32px;
    .account-container__reporting-achievement {
        flex-wrap: wrap;
        flex-direction: column;

    }


    .column-text {
        font-size: 12px;
        margin-right: 4px;
        color: black;
        margin-bottom: 6px;
    }

    .tab-list{
        border-bottom: none !important;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        .button-download{
            background: var(--main-color) !important;
            height: 28px;
            font-size: 12px;
            color: white;
        }

        .tab-item{
            background: white;
            font-size: 12px;
            border: 1px solid rgb(203, 203, 203);
            color: rgb(160, 160, 160);
            padding: 4px 8px;
            margin-right: 8px;
            border-radius: 4px;
            font-weight: 600;
        }

        .tab-item[aria-selected=true]{
            background: var(--main-color);
            color: white;
            border-color: var(--main-color);
        }

      
    }

    .table-column__item:last-child:before{
        display: none;
    }

    .table-panel{
        padding: 0;
    }

    .table-container {
        height: calc(100vh - 300px) !important;
    }
}
