.table-template__container{
    // width: calc(100vw - 354px);
  


    .table-container{
        height: calc(100vh - 246px);
        border-bottom: 1px solid #cecece;


        .table-head__item::after{
            content: "";
            position: absolute;
            right: 0;
            bottom: 8px;
            top: 8px;
            border-right: 1px solid #dfdfdf;
        }


        .divider::after{
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            border-right: 1px solid #dfdfdf;
        }

        // .table-head__item:last-child:before,
        .table-column__item:last-child:before{
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            border-left: 1px solid #dfdfdf;
        }

        table{
            .row-head__container{
                overflow: hidden;
              
        
        // ----------------------------- TABLE HEAD ----------------------------

                .table-head__item{
                    background: #ff9090;
                    color: white;
                    font-size: 12px;
                    position: sticky;
                    z-index: 2;
                    top:0;
                    padding: 12px;
                    font-style: normal;
                    text-transform: capitalize;

                    .icon{
                        position: absolute;
                        top: 12px;
                        right: 16px;
                        cursor: pointer;
                    }
                }

               
        
                .table-head__item:first-child{
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
        
                .table-head__item:last-child{
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
        
                
                
            }
        }
    }
        // ----------------------------- TABLE COLUMN ----------------------------

    .row-column__container{
        vertical-align: baseline;
        .table-column__item{
            padding: 12px;
            width: 100px;
            position: relative;
            font-size: 12px;
           
            border-bottom: 1px solid #cecece;
            background: white;

            p{
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
            }
        }
    }

    //--------------------------------- GREY BOX ----------------------------------------

   .grey-box__container{
        padding: 0;
            .grey-box{
                margin-top: 14px;
                background: #ededed;
                // background: red;
                color: white;
                font-size: 12px;
                height: 28px;
                font-style: normal;
                text-transform: capitalize;
                margin-top: 6px;
                font-size: 10px;
                position: relative;
                color: black;
                padding: 0 12px;
                display: flex;
                align-items: center;
            }

            .grey-box::after{
                content: "";
                position: absolute;
                right: 0;
                bottom: 4px;
                top: 4px;
                border-right: 1px solid #dbdbdb;
            }
   }

   .grey-box__container:first-child{
            .grey-box{
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
               
            }
   }

    //------------------------------- PAGINATION ----------------------------------------


    .section-pagination__container{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 16px;
    
        .showing-text {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
    
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            color: #5c6c8b;

            span{
                font-weight: 600;
                margin-left: 4px;
            }
        }
    
        .pagination-host-menu__container {
            ul {
                display: flex;
                list-style-type: none;
    
                .selected {
                    background: var(--main-color-7);
    
                    a {
                        color: white !important;
                    }
                }
    
                li {
                    width: 24px;
                    height: 24px;
                    border: 1px solid #b1b1b1;
                    border-radius: 4px;
    
                    text-decoration: none;
                    margin: 0 4px;
    
                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 24px;
                        height: 24px;
                        font-family: var(--main-font);
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        text-align: center;
                        letter-spacing: 1px;
    
                        color: #1a1a1a;
                    }
                }
            }
        }
    }
    

}

.table-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.date-picker__container__form-field{
    width: 209px;
    height: 32px;
    background: white;
    font-family: var(--main-font);
    font-weight: 300;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    color: #2D2D2D;
    outline: none;
}

