.loading-state1__container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon-jiggle{
        animation-name: animate;
        animation-iteration-count: infinite;
        animation-duration: 8s;
    }

    .loading-state__text{
        font-size: 16px;
        font-weight: 500;
    }
}

@keyframes animate {
    0% {transform: translateY(0);}
    25% {transform: translateY(-8px);}
    50% {transform: translateY(0);}
    75% {transform: translateY(-8px);}
    100% {transform: translateY(0);}

  }

  .popup-loading_container{
    padding: 0;
    width: 180px;
    height: 180px;
    position: relative;

    .icon-loading{
        position: absolute;
        top: 54px;
        left: 60px;
    }

}


.loading-state2__container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-state3__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 100px);

    .box{
        height: 200px;
        width: 200px;
        background: rgba(0,0,0,0.2);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center ;
        z-index: 10;
    }
}

.loading-state3__wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    cursor: not-allowed;
}