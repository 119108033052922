.global-header__container {
    height: 56px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--default-grey);
    z-index: 3;
    background: white;

    .right-side{
        align-items: center;

        .username-container {
            position: relative;
            padding: 16px 20px 16px 20px;
            border-top: 1px solid var(--default-grey);
            cursor: pointer;
            align-items: center;
    
            .username-text__global-header {
                font-family: var(--main-font);
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;
                color: #2d2d2d;
                margin-left: 8px;
            }
        }
    }

}