.section-list-live__container__mobile{
    margin-top: 32px;

    .item{
        margin-bottom: 32px;

        .head{
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;

            .label-list{
                font-size: 12px;
                font-weight: 600;
            }

            .button{
                font-size: 10px;
                color: var(--main-color);
                background: white;
                height: max-content;
                padding: 0;
            }
        }
        .table-head__item:first-child,
        .table-column__item:first-child{
            padding: 4px;
            position: sticky;
            left: 0;
            z-index: 10 !important;
            .column-button{
                padding: 4px 8px;
                height: 16px;
                background: #4e9ead;
                font-size: 8px;
                color: white;
                
            }
        }

        .table-column__item{
            p{
                word-break: keep-all !important;
            }
        }
    
      
       
    }

   

    .column-account{
        padding: 2px 4px;
        margin: 0;

        span{
            font-weight: 600;
        }
    }
}