.filter-popup__container{
    position: relative;

    .box-filter{
        border: 1px solid #b1b1b1;
        width: max-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 34px;
        padding: 0 12px;
        border-radius: 4px;
        cursor: pointer;
        
        
        .placeholder,
        .selected-text{
            font-size: 12px;
            margin-right: 32px;
            color: grey;
        }

        .selected-text{
            color: black;
            font-weight: 600;
            white-space: nowrap;
        }

         
        .selected-text__container::-webkit-scrollbar {
            // width: 4px !important;
            height: 4px;
        }
    
        .selected-text__container::-webkit-scrollbar-thumb {
            border-radius: 16px;
            background-color: var(--main-color);
        }
        
        .selected-text__container::-webkit-scrollbar-track {
            border-top: 2px solid white;
            border-bottom: 2px solid white;
            background: rgba(181, 176, 176, 0.3);
        }


        .icon{
            border-left: 0.5px solid #d7d7d7;
            padding-left: 8px;
        }
    }

    .floating-options__container{
        max-height: 300px;
        min-width: 200px;
        background: white;
        border-radius: 2px;
        top: 44px;
        position: absolute;
        overflow: auto;
        overflow-x: hidden;
        border: 1px solid var(--default-grey);
        z-index: 9;
        right: 0;

        .footer{
            position: sticky;
            bottom: 0;
            padding: 8px;
            background: white;
            left: 0;
            right: 0;
            display: flex;
            justify-content: flex-end;
            gap: 8px;

            .button-cancel, .button-submit{
                border-radius: 2px;
                background: white;
                cursor: pointer;
                height: 24px;
                padding: 7px 12px;
                width: max-content;
                color: var(--main-color);
                font-family: Poppins;
                font-size: 9px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px;
            }

            .button-submit{
                background: var(--main-color);
                color: white;
            }
        }

        .search-input{
            padding:8px ;
            border-bottom: 1px solid #b5b5b5;
            margin-bottom: 8px ;
            position: sticky;
            top: 0;
            background: white;

            input{
              border: 1px solid #c3c3c3;
              height: 30px;
              font-size: 12px;
              color: black;
              font-weight: 600;
              border-radius: 0;
              padding: 0 8px;
            }

            svg{
                position: absolute;
                right: 16px;
                top: 16px;
                z-index: 2;
                cursor: pointer;
            }

            input::placeholder{
                font-weight: 400;
            }
        }

        .filter-item{
            height: 32px;
            border-bottom: 1px solid #bfbfbf;
            background: white;
            padding: 4px 8px;
            align-items: center;
            cursor: pointer;
            display: flex;
            justify-content: space-between;

            .text{
                font-size: 12px;
                font-weight: 600;
                width: max-content;
            }
        }

        .filter-item:last-child{
            border-bottom: none;
        }

        .filter-item:hover{
            background: var(--main-color-3);

            .text{
                color: white;
            }
        }

       
    }

    .floating-options__container::-webkit-scrollbar-thumb {
        border-radius: 16px;
        background-color: var(--main-color);
    }
    
    .floating-options__container::-webkit-scrollbar-track {
        border-top: 2px solid white;
        border-bottom: 2px solid white;
        background: rgba(181, 176, 176, 0.3);
    }
    
    .floating-options__container::-webkit-scrollbar {
        width: 4px;
    }
}