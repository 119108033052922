.not-found__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    border: 0.5px solid #d7d7d7;
    border-radius: 4px;
    height: 100%;
    .title {
       font-family: var(--main-font);
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        margin: 28px 0 24px;
        color: #2d2d2d;
    }

    .desc {
        width: 350px;
        font-family: var(--main-font);
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #2d2d2d;
    }

    button{
        margin-top: 16px;
        height: 34px;
        background: var(--main-color);
        color: white;
    }

    button:hover{
        background: var(--main-color);
    }
}
