.section-header__container__voucher {
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .left-side {
        display: flex;
        align-items: center;

        .title {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
        }
    }

    .showing-text {
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        display: flex;
        align-items: center;
        text-align: center;
        color: #5c6c8b;

        span {
            font-weight: 600;
            margin-left: 4px;
        }
    }
}
