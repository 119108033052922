.popup-loading-download-global__container {
    height: 62px;
    background: #5079c9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 12px;
    border-radius: 2px;
    margin: 8px 0;
    position: fixed;
    left: 32px;
    bottom: 100px;
    border-radius: 4px;
    z-index: 9999;

    .box-circular-progress {
        width: 32px;
        height: 32px;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        margin-right: 11px;
        padding: 5px;
    }

    .text-desc-modal {
        width: 152px;
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: white;
    }
}
