.search-container {
    position: relative;
 
    .search-icon__container {
        width: 26px;
        height: 26px;
        border-radius: 4px;
        border: 1px solid #c9c9c9;
        display: flex;
        justify-content: center;
        align-items: center;
    }


  

}

.modal-search-box__container__mobile{
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    width: 80vw;

    .label{
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 8px;
    }

    input{
        border: 1px solid grey;
        border-radius: 4px;
        margin-bottom: 16px;
        height: 32px;
        padding-left: 16px;
        font-size: 10px;
    }

    .button-submit{
        width: 100%;
        background: var(--main-color);
        color: white;
        font-size: 10px;
        height: 30px;
    }
}