.section-create-and-edit__container__voucher {
    overflow: auto;
    height: 100%;

    //-----------------------------HEAD ---------------------------------

    .header {
        padding: 24px;
        width: 100%;
        padding-bottom: 25px;
        align-items: center;
        position: sticky;
        top: 0;
        z-index: 2;
        background: white;
        border-bottom: 1px solid var(--default-grey);

        .title {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;
            text-align: center;
            color: #2d2d2d;
            margin-left: 16px;
        }
    }

    //-------------------------------- BODY ---------------------------------

    .body {
        margin-top: 64px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .form-field__container {
            max-width: 500px;
            width: 100%;
        }

        .text-area__container {
            height: 100px;
            padding-top: 16px;
        }
    }

    //------------------------------ FOOTER --------------------------------------
    .footer {
        max-width: 500px;
        width: 100%;
        margin: 32px auto;
        gap: 8px;
        justify-content: flex-end;

        button {
            background: var(--main-color);
            border-radius: 2px;
            cursor: pointer;
            height: 32px;
            padding: 7px 12px;
            width: 95px;

            color: #fff;
            font-family: Poppins;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
        }

        .cancel-button {
            background: none;
            border: 1px solid var(--main-color);
            color: var(--main-color);
        }
    }
}

//------------------------- HIDE SCROLL ---------------------------------------

.section-create-and-edit__container__voucher::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.section-create-and-edit__container__voucher {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
