.sidemenu-children__container{
    padding-left: 24px;
    overflow: hidden;
    transition: 0.5s;

    .section-body-item__side-menu{
        position: relative;

      
    }


    .section-body-item-__side-menu-children__active::after{
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        position: absolute;
        right: 7px;
        border-radius: 7.5px;
        background-color: #ff6879f5;
    }

 
  
}

