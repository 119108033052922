.section-body__container__target-schedule{
width: 100%;
    .brand-container__target-schedule{
        align-items: center;
        flex-wrap: wrap;

        img{
            height: 32px;
            width: 32px;
            margin-right: 8px;
            border-radius: 50%;
            cursor: pointer;
            object-fit: cover;
            border: 1px solid #e3e3e3;
        }

        .column-text{
            width: 24px;
            height: 24px;
            text-align: center;
            font-size: 10px;
            border-radius: 50%;
            margin-right: 4px;
            color: white;
            
        }
    }

    .box-icon,
    .remove-icon {
        border: 1px solid rgb(23, 70, 162);
        padding: 6px;
        border-radius: 4px;
        cursor: pointer;
    }

    .remove-icon{
        border: 1px solid rgb(255, 62, 62);
    }

    .action-container{
        gap: 8px;
    }

    .table-head__item:last-child,
    .table-column__item:last-child{
        position: sticky ;
        right: 0;
    }


    .editable-input{
        margin-left: -16px;
        font-size: 12px;
        color: rgb(23, 70, 162);
        border: 1px solid rgb(23, 70, 162);
        font-weight: 600;
    }

    .save-button__edit,
    .cancel-button__edit{
        background: var(--main-color) !important;
        height: 24px;
        font-size: 12px;
        color: white;
        border-radius: 4px;
    }
    
    .cancel-button__edit{
        background: white !important;
        color: var(--main-color);
        border: 1px solid var(--main-color);
    }
    
}