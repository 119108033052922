.App{
    background: rgb(248, 248, 248) !important;
}

*{
    letter-spacing: 0;
    font-family: 'Poppins';
}

.chakra-modal__content-container{
    display: flex;
    align-items:center !important;
    height: 100vh;
    .chakra-modal__content{
        margin: 0 !important;
        overflow-x: hidden !important;
    }
}

.modal-content__container::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: var(--main-color);
}

.modal-content__container::-webkit-scrollbar-track {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    background: rgba(181, 176, 176, 0.3);
}

.modal-content__container::-webkit-scrollbar {
    width: 4px;
}

.view-mobile__only{
    @media(min-width: 700px){
        display: none !important;
    }
}