.search-by-column__container{
    .icon-container{
        position: absolute;
        right: 12px;
        top: 12px;
        cursor: pointer;
    }

    .label-search{
        font-size: 12px;
        font-weight: 600;
        color: grey;
        text-transform: none;
    }

    .search-box{
        position: absolute;
        left: 0;
        top: 54px;
        background: white;
        border: 1px solid var(--default-grey);
        border-radius: 4px;
        padding: 12px 8px;

        input{
            border: 1px solid var(--default-grey);
            height: 32px;
            margin-bottom: 12px;
            outline: none;
            color: rgb(81, 81, 81);
            padding:0 16px;
        }

        button{
            width: 100%;
            background: var(--main-color);
            border-radius: 2px;
            cursor: pointer;
            height: 28px;
            padding: 7px 12px;
            color: #fff;
            font-family: Poppins;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
        }
    }
}