.global-button__container {
    /// text
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: var(--main-color);
    /// Button
    height: 34px;
    border: 1px solid var(--main-color);
    border-radius: 4px;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 7px 12px;
}

.global-button-white-stroke__container {
    /// text
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: var(--main-color);
    /// Button
    height: 34px;
    border: 1px solid var(--main-color);
    border-radius: 4px;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 7px 12px;
    >svg>path{
        stroke: white;
    }
}

.global-button-save__container{
    text-align: center;
    font-weight: bold !important;
    background-color: #AFF4C6 !important;
    color: #13AE5C;
    font-size: 12px !important;
    padding: 7px 12px;
    border-width: 1px;
    border-color: #13AE5C;
    height: 34px !important;
}

.global-button-cancel__container{
    text-align: center;
    font-weight: bold !important;
    background-color: #ffb3b3 !important;
    color: #f33535;
    font-size: 12px !important;
    padding: 7px 12px;
    border-width: 1px;
    border-color: #f33535;
    height: 34px !important;
}