.mobile-header__container {
    height: 56px;
    background: white;
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.05);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
    z-index: 20;
    .logo-text {
        margin-left: 8px;
        font-size: 16px;
        font-weight: 600;
    }
    transform: translateY(0);
    opacity: 1;
    transition: 0.4s;

    svg {
        width: 84px;
        height: 40px;
    }
}

.active-header__mobile {
    opacity: 0;
    transition: 0.4s;
    transform: translateY(-80px);
}
