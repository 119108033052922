.section-search-and-filter__container{
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .filter-month{
        border: 1px solid rgb(181, 181, 181);
        height: max-content;
        padding:4px 8px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        position: relative;
        width: max-content;

        .divider{
            position: absolute;
            top: 4px;
            bottom: 4px;
            width: 1px;
            background: grey;
            left: 30px;
        }

        .filter-text{
            font-size: 10px;
            font-weight: 500;
            padding:0 12px 0 16px;
            font-weight: 700;
            color: var(--main-color);
        }
    }
}