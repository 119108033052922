.invalid-data__table{
    height: 85vh;
    padding: 24px;
    overflow: auto;

    .table-column__item:last-child::before{
        display: none !important;
    }

    .error-actived{
        color: red;
        cursor: pointer;
    }


    td:has(.error-actived) {
        border: 1px solid red !important;
    }

    .input-edit{
        height: 32px;
        font-size: 10px;
        border-radius: 0;
    }
}