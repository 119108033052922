.side-menu__wrapper {
    border-right: 1px solid var(--default-grey);
    height: 100vh;
    background: white;

    .side-menu__container {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding-bottom: 64px;

        .section-head__side-menu {
            padding: 18px 22px;
            position: relative;
            height: 72px;
            display: flex;
            align-items: center;
            // justify-content: space-between;

            .icon-expand__container {
                cursor: pointer;
                position: absolute;
                right: -16px;
                top: 16px;
                background: white;
                padding: 4px;
                z-index: 9;
            }

            .logo-text {
                margin-right: auto;
                font-weight: 800;
                font-size: 20px;
                text-align: center;
                color: rgb(122, 122, 122);
            }

            .logo-image {
                margin-right: 8px;
                width: 100px;
            }
        }

        .section-body__side-menu {
            padding: 32px 12px 32px 12px;

            .section-body-item__side-menu {
                position: relative;
                padding: 11px 0 11px 22px;
                cursor: pointer;
                height: 40px;
                margin-bottom: 16px;
                align-items: center;

                .section-body-item__text {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    text-transform: capitalize;
                    color: #2d2d2d;
                    margin-left: 12px;
                }
            }

            .section-body-item__side-menu:hover {
                background: var(--main-color);
                opacity: 0.5;
                border-radius: 4px;

                .section-body-item__text {
                    color: white;
                }

                svg {
                    path,
                    circle,
                    rect {
                        stroke: white !important;
                    }
                }
            }

            .section-body-item__side-menu__active {
                background: var(--main-color);
                border-radius: 4px;

                .section-body-item__text {
                    color: white;
                    font-weight: 500;
                }
            }
        }
    }

    .arrow-icon {
        position: absolute;
        right: 6px;
        transition: 0.25s;
    }

    .arrow-icon__transform {
        transform: rotate(90deg);
    }
}
