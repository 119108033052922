.maindashboard__container__mobile{
    background: white;
    width: 100%;
    padding: 16px 0;
    height: max-content;

    @media(min-width: 767px){
        display: none;
    }

}