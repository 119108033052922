.section-header__container__target-achievement {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .left-side {
        display: flex;
        align-items: center;

        .title {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
        }
    }


    .right-side{
        display: flex;
        align-items: center;

        .upload-button{
            background: var(--main-color) !important;
            height: 32px;
            font-size: 12px;
            color: white;
        }
    }

   

    .react-datepicker-wrapper {
        border: 1px solid #b0b7bf;
        border-radius: 4px;
        cursor: pointer;
    }

    .session-form {
        color: black;


        .react-datepicker__view-calendar-icon {
            input {
                padding: 6px 8px 6px 12px
            }
        }

        .react-datepicker__input-container {
            display: flex;
            flex-direction: row-reverse;
            outline: none;

            input {
                font-size: 13px;
                width: 100px;
                font-weight: 500;
            }

            svg {
                padding-top: 4px;
                margin-top: 2px;
                margin-right: 12px;
            }
        }

    }
}