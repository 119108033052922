.empty-state-global-mobile__container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
    padding: 24px  0;
    .empty-state__text {
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        color: #2d2d2d;
        text-transform: capitalize;
    }

    .empty-state__desc {
        width: 310px;
        text-transform: capitalize;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #2d2d2d;
    }
}
