.table-skeleton__container {
    .section-head__container__table-skeleton {
    background: var((--main-color));
       width: 100%;
       justify-content: space-between;
       gap: 8px;
       margin-bottom: 16px;
       height: 40px;
       border-radius: 4px;
       align-items: center;
       padding: 0 16px;

        .section-head__item-container {
        }

    }

    .section-body__container__table-skeleton {
        margin-bottom: 24px;
        padding: 0 16px;
        gap: 8px;
        justify-content: space-between;


        .section-body__item-container {
            justify-content: center;

        }
    }

    .section-body__container__table-skeleton:nth-child(odd) {
    }
}
