.section-body__container__coin{

    .account-container__coin{
        flex-wrap: wrap;
        flex-direction: column;

        .column-text{
            font-size: 12px;
            margin-right: 4px;
            color: black;
            margin-bottom: 6px;
        }
    }
    .box-icon,
    .remove-icon {
        border: 1px solid rgb(23, 70, 162);
        padding: 6px;
        border-radius: 4px;
        cursor: pointer;
    }

    .remove-icon{
        border: 1px solid rgb(255, 62, 62);
    }

    .action-container{
        gap: 8px;
    }

    .table-head__item:last-child,
    .table-column__item:last-child{
        position: sticky ;
        right: 0;
    }


    span{
        font-weight: 600;
        margin-left: 4px;
    }


    .checkbox-field{
        .chakra-checkbox__control{
            border: 2px solid rgba(62, 91, 136, 0.563);
        }
    }
    
    
}