.upload-target__container{
    width: 60vw;

  .head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;

    .title{
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
    }

    .button-container__invalid-data{
        gap: 16px;

        button{
            width: max-content;
            height: 28px;
            border:1px solid var(--main-color);
            border-radius: 4px;
            font-size: 12px;
        }

        .submit{
            background: var(--main-color);
            color: white;
        }

        .cancel{
            color: var(--main-color);
            background: white;
        }
    }
  
  }

  .upload-process__box{
    display: flex;
    border: 1px solid  var(--main-color-3);
    background: var(--main-color-3);
    border-radius: 4px;
    height: 72px;
    padding: 16px;
    margin: 32px;
    margin-bottom: 64px;

    .icon{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
    }

    .progress{
        flex: 1;

        .process-text{
            flex: 1;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 4px;

            .text{
                font-size: 13px;
                font-weight: 500;
                margin: 0;
            }

        }
      
    }
}

 .body{
    padding: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 80vh;

  

    .file-uploader__drag-and-drop-only,
    .drag-zone__actived{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        height: 100%;
        z-index: 3;
    }

    .drag-zone__actived,{
        background: #cdffe7;
        opacity: 1;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;

        .text{
            font-size: 14px;
            color: green;
            font-weight: 600;
        }
    }

    .upload-box{
        position: relative;
        border: 1px dotted black;
        height: 60%;
        width: 100%;
        max-width: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .upload-text{
            font-size: 12px;
            font-weight: 500;
            margin: 16px 0 32px;
        }

        .upload-button{
            background: var(--main-color);
            height: 28px;
            color: white;
            font-size: 12px;
            border: none;
        }
    }
 }
   
}