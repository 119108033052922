.mobile-footer__container{
    height: 60px;
    background: white;
    box-shadow: 2px 4px 16px rgba(0,0,0,.05);
    border-top:1px solid var(--default-grey) ;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
    padding: 0 32px;
    z-index: 11;

    .item{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

        .text{
            font-size: 11px;
            margin-top: 4px;
        }
    }

    .active{
        .text{
            color: var(--main-color);
        }
    }

    .circle-active__container{
        position: absolute;
        top: 0;
        right: 0;
    }
}