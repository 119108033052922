.popup-template__container,
.popup-confirmation__container,
.popup-loading_container {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: max-content;
    width: 330px;
    padding-bottom: 48px;
    position: relative;
    padding-top: 24px;
    min-height: 354px;

    @media (max-width: 767px) {
        width: 80vw;
        padding: 16px;
    }

    .illustration-container {
        width: 240px;
        height: 240px;
    }

    .icon-close {
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
    }

    .title,
    .description {
        font-weight: 600;
        font-size: 14px;
        position: relative;
        z-index: 3;
        padding: 0 48px;
        text-align: center;
    }

    .description {
        font-size: 13px;
        margin-top: 8px;
        font-weight: 400;
    }

    .icon-jiggle {
        animation-name: example;
        animation-iteration-count: infinite;
        animation-duration: 8s;
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        margin-top: 32px;

        button {
            background: var(--main-color);
            border-radius: 2px;
            cursor: pointer;
            height: 28px;
            padding: 7px 12px;
            width: 95px;

            color: #fff;
            font-family: Poppins;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
        }

        .button-cancel {
            background: none;
            // border: 1px solid var(--main-color);
            border: none;
            color: var(--main-color);
        }
    }
}

.popup-comment__container::-webkit-scrollbar-track {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    background: rgba(181, 176, 176, 0.3);
}

.popup-comment__container::-webkit-scrollbar {
    width: 4px;
}

.popup-comment__container::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: var(--main-color);
    padding-right: 8px;
}


.popup-comment__container {
    width: 600px;
    padding: 16px;
    height: 60vh;
    overflow: auto;

    .comment{
        font-size: 12px;
        font-weight: 600;
    }

    .name-text{
        font-size: 14px;
        font-weight: 500;
    }

    .comment-text{
        font-size: 12px;
        color: #343434;
    }

    .date-text{
        font-size: 12px;
        color: #343434;
    }
}

@keyframes example {
    0% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(8px);
    }
    50% {
        transform: translateY(0);
    }
    75% {
        transform: translateY(8px);
    }
    100% {
        transform: translateY(0);
    }
}

.popup-loading_container {
    padding: 0;
    width: 180px;
    height: 180px;
    position: relative;

    .icon-loading {
        position: absolute;
        top: 54px;
        left: 60px;
    }

    .box1,
    .box2,
    .box3 {
        box-sizing: border-box;
        position: absolute;
        display: block;
        border: 8px solid var(--main-color);
    }
    .box1 {
        //   border: 8px solid rgb(224, 109, 109);
        width: 56px;
        height: 24px;
        margin-top: 32px;
        margin-left: 0px;
        -webkit-animation: anime1 4s 0s forwards ease-in-out infinite;
        animation: anime1 4s 0s forwards ease-in-out infinite;
    }
    .box2 {
        //   border: 8px solid rgb(57, 115, 133);
        width: 24px;
        height: 24px;
        margin-top: 0px;
        margin-left: 0px;
        -webkit-animation: anime2 4s 0s forwards ease-in-out infinite;
        animation: anime2 4s 0s forwards ease-in-out infinite;
    }
    .box3 {
        //   border: 8px solid rgb(175, 43, 107);
        width: 24px;
        height: 24px;
        margin-top: 0px;
        margin-left: 32px;
        -webkit-animation: anime3 4s 0s forwards ease-in-out infinite;
        animation: anime3 4s 0s forwards ease-in-out infinite;
    }
    @-webkit-keyframes anime1 {
        0% {
            width: 56px;
            height: 24px;
            margin-top: 32px;
            margin-left: 0px;
        }
        12.5% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 0px;
        }
        25% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 0px;
        }
        37.5% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 0px;
        }
        50% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 0px;
        }
        62.5% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 0px;
        }
        75% {
            width: 24px;
            height: 56px;
            margin-top: 0px;
            margin-left: 0px;
        }
        87.5% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 0px;
        }
        100% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 0px;
        }
    }
    @keyframes anime1 {
        0% {
            width: 56px;
            height: 24px;
            margin-top: 32px;
            margin-left: 0px;
        }
        12.5% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 0px;
        }
        25% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 0px;
        }
        37.5% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 0px;
        }
        50% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 0px;
        }
        62.5% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 0px;
        }
        75% {
            width: 24px;
            height: 56px;
            margin-top: 0px;
            margin-left: 0px;
        }
        87.5% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 0px;
        }
        100% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 0px;
        }
    }
    @-webkit-keyframes anime2 {
        0% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 0px;
        }
        12.5% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 0px;
        }
        25% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 0px;
        }
        37.5% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 0px;
        }
        50% {
            width: 56px;
            height: 24px;
            margin-top: 0px;
            margin-left: 0px;
        }
        62.5% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 32px;
        }
        75% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 32px;
        }
        87.5% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 32px;
        }
        100% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 32px;
        }
    }
    @keyframes anime2 {
        0% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 0px;
        }
        12.5% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 0px;
        }
        25% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 0px;
        }
        37.5% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 0px;
        }
        50% {
            width: 56px;
            height: 24px;
            margin-top: 0px;
            margin-left: 0px;
        }
        62.5% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 32px;
        }
        75% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 32px;
        }
        87.5% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 32px;
        }
        100% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 32px;
        }
    }
    @-webkit-keyframes anime3 {
        0% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 32px;
        }
        12.5% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 32px;
        }
        25% {
            width: 24px;
            height: 56px;
            margin-top: 0px;
            margin-left: 32px;
        }
        37.5% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 32px;
        }
        50% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 32px;
        }
        62.5% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 32px;
        }
        75% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 32px;
        }
        87.5% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 32px;
        }
        100% {
            width: 56px;
            height: 24px;
            margin-top: 32px;
            margin-left: 0px;
        }
    }
    @keyframes anime3 {
        0% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 32px;
        }
        12.5% {
            width: 24px;
            height: 24px;
            margin-top: 0px;
            margin-left: 32px;
        }
        25% {
            width: 24px;
            height: 56px;
            margin-top: 0px;
            margin-left: 32px;
        }
        37.5% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 32px;
        }
        50% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 32px;
        }
        62.5% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 32px;
        }
        75% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 32px;
        }
        87.5% {
            width: 24px;
            height: 24px;
            margin-top: 32px;
            margin-left: 32px;
        }
        100% {
            width: 56px;
            height: 24px;
            margin-top: 32px;
            margin-left: 0px;
        }
    }
}
