.detail-schedule__container__mobile {
    .detail-live__container {
        background: white;
        width: 100%;
        margin: 4px 0;
        padding: 16px;
        border-bottom: 1px solid #dfdfdf;
        position: relative;

        .label-section {
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 24px;
            display: flex;
        }

        .see-other {
            margin: 0;
            font-size: 10px;
            color: var(--main-color);
        }

        .items-container {
            overflow: auto;
        }

        .detail-live {
            align-items: center;
            margin-bottom: 4px;

            .more-detail__container {
                margin-top: 8px;
                align-items: center;

                div {
                    transition: 0.5s;
                }

                .more-detail__text {
                    font-size: 10px;
                    font-weight: 600;
                    color: var(--main-color);
                }
            }

            .more-detail-box__container {
                .item-container {
                    overflow: auto;
                }
            }

            .expand-active {
                height: 80px;
            }

            .event-title {
                font-size: 16px;
                font-weight: 600;
            }

            .event-type {
                padding: 4px 8px;
                font-size: 10px;
                color: white;
                border-radius: 8px;
                margin-right: 4px;
            }

            .event-studio {
                padding: 4px 8px;
                font-size: 10px;
                color: white;
                border-radius: 8px;
                background: var(--main-color);
            }

            .event-container {
                overflow: auto;
            }

            .event-items {
                min-width: 90px;
            }

            .event-items,
            .event-item {
                white-space: nowrap;
                font-size: 12px;
                font-weight: 700;
                margin-top: 8px;
                margin-right: 4px;
            }

            .event-item {
                font-weight: 500;

                span {
                    font-weight: 700;
                }
            }

            .event-date {
                font-size: 12px;
                margin-top: 8px;
            }

            .event-time {
                font-size: 10px;
                background: var(--main-color);
                width: max-content;
                border-radius: 2px;
                color: white;
                padding: 4px 8px;
            }
        }

        .additional-live__container {
            .additional-live {
                margin-bottom: 16px;
                border-bottom: 1px dotted grey;
            }

            .empty-text {
                height: 86px;
                color: grey;
                font-size: 11px;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .label-additional {
                font-size: 12px;
                color: black;
                font-weight: 600;
            }

            .text-item {
                font-size: 11px;
                font-weight: 500;
                margin-left: 10px;
                margin-bottom: 8px;
            }

            .rich-text__box {
                margin-left: 20px;
                margin-bottom: 16px;
                p,
                ol {
                    font-size: 10px;
                    color: black;
                }

                ol {
                    margin-left: 8px;
                }
            }
        }

        .revenue-container {
            .revenue-box {
                border-bottom: 1px dotted grey;
                margin-bottom: 24px;
                .revenue-account {
                    font-size: 12px;
                    color: #335a53;
                    font-weight: 600;
                    margin-bottom: 24px;
                }

                .total-wrapper {
                    flex-wrap: wrap;
                    .total-container {
                        margin-bottom: 32px;
                        width: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        .total-text {
                            font-size: 14px;
                            font-weight: 700;
                            word-break: break-all;
                            text-align: center;
                            padding: 0 8px;

                            span {
                                margin-right: 2px;
                            }
                        }

                        .revenue-label {
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
