.finishlive-screen__container__maindashboard {
    .cancel-live {
        background: #e36565;
        color: white;
        height: 20px;
        font-size: 8px;
        border-radius: 2px;
    }

    .finishinfo {
        background: white;
        width: 100%;
        margin: 4px 0;
        padding: 16px;
        border-bottom: 1px solid #dfdfdf;
        position: relative;

        .label-section {
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 16px;
        }

        .image-account__container {
            padding: 8px;
            border: 2px dotted grey;
            border-radius: 8px;
            position: relative;
            margin-bottom: 8px;

            .remove-image__button {
                position: absolute;
                background: var(--main-color);
                width: max-content;
                height: max-content;
                padding: 4px;
                border-radius: 50%;
                top: -8px;
                right: -8px;
            }
        }

        .items-container {
            overflow: auto;
        }

        .more-detail__container {
            margin-top: 8px;
            align-items: center;

            div {
                transition: 0.5s;
            }

            .more-detail__text {
                font-size: 10px;
                font-weight: 600;
                color: var(--main-color);
            }
        }

        .form-field__container {
            .react-time-picker {
                height: 32px;

                span,
                input {
                    font-size: 10px !important;
                }
            }
        }

        .more-detail-box__container {
            .item-container {
                overflow: auto;
            }
        }

        .expand-active {
            height: 80px;
        }

        .event-studio {
            padding: 4px 8px;
            font-size: 10px;
            color: white;
            border-radius: 8px;
            background: var(--main-color);
        }

        .event-container {
            overflow: auto;
        }

        .event-items {
            min-width: 90px;
        }

        .event-items,
        .event-item {
            white-space: nowrap;
            font-size: 12px;
            font-weight: 700;
            margin-top: 8px;
            margin-right: 4px;
        }

        .event-item {
            font-weight: 500;

            span {
                font-weight: 700;
            }
        }

        .finishinfo__item {
            align-items: center;
            margin-bottom: 4px;

            .event-title {
                font-size: 16px;
                font-weight: 600;
            }

            .event-type {
                padding: 4px 8px;
                font-size: 10px;
                color: white;
                border-radius: 8px;
                margin-right: 4px;
            }

            .event-date {
                font-size: 12px;
                margin-top: 8px;
            }

            .event-time {
                font-size: 10px;
                background: var(--main-color);
                width: max-content;
                border-radius: 2px;
                color: white;
                padding: 4px 8px;
            }

            .account-container {
                display: flex;
                position: absolute;
                right: 12px;
                align-items: center;
                bottom: 8px;
                // background: var(--main-color);
                border-radius: 4px;
                padding: 4px 10px;
                // color: white;

                .account-text {
                    font-size: 11px;
                    font-weight: 500;
                    margin-left: 4px;
                }
            }

            .circle-text {
                // position: absolute;
                // right: -4px;
                // top: -0;
                margin-left: 4px;
                background: var(--main-color);
                color: white;
                border-radius: 50%;
                font-size: 7px;
                margin-top: 2px;
                width: 16px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .livestream-report {
            margin-bottom: 16px;
            border-bottom: 1px dashed grey;

            .account-name__container {
                padding: 4px 8px;
                margin-bottom: 10px;
                display: flex;
                background: var(--main-color);

                .account-name {
                    font-size: 11px;
                    font-weight: 600;
                    color: white;
                    margin-left: 4px;

                    span {
                        margin: 0 6px;
                    }
                }
            }
        }
    }

    .submit-button {
        background: var(--main-color);
        color: white;
        font-size: 12px;
        width: 100%;
        height: 36 px;
    }
}
