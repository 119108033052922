.table-template-mobile__skeleton{
    border: 1px solid #cecece;

    .header{
        gap:12px;
        background: var(--main-color);
        padding: 4px 6px;
        height: 28px;
        align-items: center;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    .skeleton-column{
        gap:12px;
        padding: 4px 6px;
        height: 33px;
        align-items: center;
        border-bottom: 1px solid #cecece;
        margin: 0;
    }
}