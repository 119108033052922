.section-body__container__schedule{
    position: relative;
   .rbc-toolbar{
    // display: none;
   }

   .fc-daygrid-body-natural{
    display: none;
   }

   .fc-day-today {
    background-color: rgba(#96e9c24d,0.2) !important;
   }

   th{
        height: 67px;
        padding: 0 0 4px 8px;
        background: rgb(255 104 121) !important;
        padding: 0;

        .fc-scrollgrid-sync-inner{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;

            a{
                font-size: 16px;
                font-weight: 600;
                color: white;
            }

            span{
                font-size: 24px;
                font-weight: 400;
                margin-right: 4px;
            }
           
        }

        @media(max-width: 700px){
            height: 36px;

            a{
                font-size: 12px !important;
                font-weight: 600;
                color: white;
            }

            span{
                font-size: 14px !important;
                font-weight: 400;
                margin-right: 4px;
            }
        }

       
   }

   .fc-timegrid-slot{
    height: 30px;
   }

   .fc-timegrid-slot-label-cushion{
    height: 40px;
    width: 70px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    @media(max-width: 700px){
        height: 36px;

        font-size: 11px;
    }
   }

   .fc-event{
    background: none !important;
    border: none;
   }

   
   .fc-timegrid-event-harness{
        a{
            box-shadow: none;

        }
   }


    .fc-scroller::-webkit-scrollbar {
            display: none;

        }
    

    .fc-scrollgrid-section-body {
        .fc-scroller::-webkit-scrollbar {
            width: 4px !important;
        }
    }
   

.fc-scroller::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: var(--main-color);
}

.fc-scroller::-webkit-scrollbar-track {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    background: rgba(181, 176, 176, 0.3);
}

/* Hide scrollbar for IE, Edge and Firefox */
    .fc-scroller {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

}