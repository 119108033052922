.login-container{
    background: var(--main-color);
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    .white-box{
        width: 500px;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        padding: 54px;
        position: relative;

        .title{
            font-weight:700;
            font-size: 39px;
            color: #333333;
            line-height: 1.2;
            text-align: center;
            margin-bottom: 49px;
        }

        .label{
            font-size: 14px;
            color: #333333;
            line-height: 1.5;
            padding-left: 7px;
        }

        .back-icon{
            position: absolute;
            left: 16px;
            top: 18px;
            cursor: pointer;
        }

       .input-field__container{ 
            position: relative;
            margin-bottom: 24px;
            
            .icon{
                position: absolute;
                left: 10px;
                top: 14px;
                z-index: 999;
            }
            .input-field{
                    font-size: 16px;
                    color: #333333;
                    line-height: 1.2;
                    display: block;
                    width: 100%;
                    height: 55px;
                    background: transparent;
                    padding: 0 7px 0 43px;
                    border: none;
                    border-bottom: 1px solid #d5d5d5;
                    border-radius: 0;
                    outline: none;
                }
            }

            .input-field::placeholder{
                color: grey;
            }
        }

        .submit-button{
            width: 100%;
            font-size: 16px;
            color: #fff;
            line-height: 1.2;
            text-transform: uppercase;
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
            height: 50px;
            background: var(--main-color);
        }

        .login-button:hover{
            background: pink;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        .input-login:focus-visible {
            box-shadow: none;
            font-size: 16px;
        }

        .forgot-password__text{
            font-size: 12px;
            color: #1a1a1a;
            background: none;
            margin-top: 12px;
            text-align: left;
        }

        .forgot-password__text:hover{
            background: none;
        }

    //--------------------------- MOBILE VIEW -----------------------------------    

    @media(max-width: 767px){
        .white-box{
            max-width: 90%;
            padding: 16px;

            .title{
                font-size: 32px;
            }
        }

        .login-button{
            height: 42px;
            font-size: 14px;
        }
    }    
}