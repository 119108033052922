.modal-multiple-date__container{
    background: white;
    border-radius: 8px;
    padding: 16px;

    .label{
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 8px;
    }
    input{
        width: calc(80vw - 32px);
        background: white;
        height: 36px;
        border: 1px solid grey;
        border-radius: 4px;
        padding: 14px;
        font-size: 12px;
    }


    .button-submit{
        height: 32px;
        font-size: 12px;
        color: white;
        background: var(--main-color);
        width: 100%;
        margin-top: 32px;
    }
}