.live-list-screen__container__maindashboard{
    padding: 16px;
    .column-button{
        padding: 4px 8px;
        height: 16px;
        font-size: 8px;
        color: white;
        
    }

    .table-head__item:first-child,
    .table-column__item:first-child{
        padding: 4px;
        position: sticky;
        left: 0;
        z-index: 10 !important;
        .column-button{
            padding: 4px 8px;
            height: 16px;
            background: #4e9ead;
            font-size: 8px;
            color: white;
            
        }
    }

}