.page-container {
    display: flex;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #f8f8f8;

    .dashboard-section-left__container {
        width: 284px;
        transition: 0.5s;
    }


  
    .dashboard-section-right__container{
        width: calc(100vw - 284px);

        .dashboard-content__container{
            position: relative;
            margin: 16px;
            height: calc(100vh - 89px);
            border-radius: 4px;
            background: white;
            border: 1px solid var(--default-grey);
        }
    }

    @media(max-width: 767px){
        padding: 56px 16px 60px 16px;
        background: white;
        overflow: auto;
    }
}

.hide-sidemenu{
    .dashboard-section-left__container {
        width: 0;

        .section-body__side-menu{
            display: none;
        }
    }

    .dashboard-section-right__container{
        width: calc(100vw - 32px);
    } 
}