.form-field__container {
    margin-bottom: 16px;

    .label {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #2d2d2d;
        margin-bottom: 8px;
        height: 24px;
        text-transform: capitalize;
        @media (max-width: 767px) {
            font-size: 11px;
            margin-bottom: 4px;
        }
    }

  

    .react-date-picker,
    .input-field,
    .camera-button,
    .input-time-picker-native__container {
        width: 100%;
        border: 1px solid rgb(165, 165, 165);
        box-sizing: border-box;
        height: 48px;
        padding: 0 16px;
        border-radius: 4px;
        background: white;
        flex: 1;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        outline: grey;
        color: #2d2d2d;

        @media (max-width: 767px) {
            height: 32px;
            font-size: 10px;
        }
    }

    .camera-button {
        display: flex;
        align-items: center;
        position: relative;

        .icon {
            position: absolute;
            right: 8px;
            top: 4px;
        }
    }

    .input-field::placeholder,
    .text-placeholder {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        @media (max-width: 767px) {
            font-size: 10px;
        }
    }

    .error-empty__field {
        border: 0.5px solid var(--error-color) !important;
    }

    .error-field__text {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: var(--error-color);
        margin-top: 4px;
    }

    .react-date-picker__wrapper {
        border: none;
        cursor: pointer;

        input {
            outline: none;
            padding: 0 8px;
        }

        .react-date-picker__calendar-button {
            padding-right: 16px;
        }
    }

    //------------------------ TIME ---------------------------------------------
    .react-time-picker {
        width: 100%;
        height: 48px;

        .react-time-picker__wrapper {
            border-radius: 4px;
            padding-left: 16px;

            .react-time-picker__inputGroup {
                input,
                span {
                    font-size: 14px !important;
                    outline: grey;
                }
            }
        }
    }

    //------------------------ TIME PICKER 2 ---------------------------------------------

    .time-field__container{
        border: 1px solid rgb(165, 165, 165);
        height: 32px;
        padding: 0 16px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 10px;

     
    }

    .editor-field__container {
        height: 180px;
        overflow: auto;
        border-radius: 2px;
        border: 1px solid rgb(165, 165, 165);
        padding: 0 16px;
        font-size: 12px;

        .DraftEditor-editorContainer {
            z-index: 0;
        }
    }

    .editor-field-toolbar__container {
        border: 1px solid rgb(165, 165, 165);
    }
}

.form-field__container_comment {
    margin-bottom: 16px;
    width: 100%;
    top: 0;
    position: sticky;
    background: white;

    .label {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #2d2d2d;
        margin-bottom: 8px;
        height: 24px;
    }

    .button{
        width: 100px;
        background: var(--main-color) !important;
        color: white;
        height: 32px;
        font-size: 10px;
        width: 100px;
    }

    .react-date-picker,
    .input-field {
        width: 100%;
        border: 1px solid rgb(165, 165, 165);
        box-sizing: border-box;
        height: 48px;
        padding: 0 16px;
        border-radius: 4px;
        background: white;
        flex: 1;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        outline: grey;
        color: #2d2d2d;
    }

    .input-field::placeholder {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #9c9898;
    }

    .error-empty__field {
        border: 0.5px solid var(--error-color) !important;
    }

    .error-field__text {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: var(--error-color);
        margin-top: 4px;
    }

    .react-date-picker__wrapper {
        border: none;
        cursor: pointer;

        input {
            outline: none;
            padding: 0 8px;
        }

        .react-date-picker__calendar-button {
            padding-right: 16px;
        }
    }

    //------------------------ TIME ---------------------------------------------
    .react-time-picker {
        width: 100%;
        height: 48px;

        .react-time-picker__wrapper {
            border-radius: 4px;
            padding-left: 16px;

            .react-time-picker__inputGroup {
                input,
                span {
                    font-size: 14px !important;
                    outline: grey;
                }
            }
        }
    }
}


.time-picker__custom-container{
    width: 90vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    .time-select{
        font-size: 20px;
        // height: 30vh;
        overflow: scroll;
    }

    .separator{
        font-size: 20px;
    }
    
}

.react-ios-time-picker-popup{
    background: rgba(0,0,0,0.6);
    align-items: center !important;

    
}

.react-ios-time-picker-input{
    width: 100%;
    border: 1px solid rgb(165, 165, 165);
    box-sizing: border-box;
    height: 32px;
    padding: 0 16px;
    border-radius: 4px;
    background: white;
    flex: 1 1;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    outline: grey;
    color: #2d2d2d;

    @media (min-width: 767px){
        height: 48px;
        font-size: 12px;
    }
}


.select-image__container{
    width: max-content;
    position: absolute;
    z-index: 2;
    bottom: 32px;
    left: 32px;
    background: white;
    border-radius: 8px;
    padding: 4px;

    .input-field{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        opacity: 0;
    }
}