.section-body__container__usermanagement{

    .brand-container__usermanagement{
        align-items: center;
        flex-wrap: wrap;

        img{
            height: 32px;
            width: 32px;
            margin-right: 8px;
            border-radius: 50%;
            cursor: pointer;
            object-fit: cover;
            border: 1px solid #e3e3e3;
        }

        .column-text{
            width: 24px;
            height: 24px;
            text-align: center;
            font-size: 10px;
            border-radius: 50%;
            margin-right: 4px;
            color: white;
            
        }
    }
    //--------------- FREZEE TABLE -----------------------------

    .table-head__item:nth-child(1),
    .table-column__item:nth-child(1),
    .grey-box__container:nth-child(1){
        position: sticky;
        left: 0;
        z-index: 1;
    }

    .table-head__item:nth-child(2),
    .table-column__item:nth-child(2),
    .grey-box__container:nth-child(2)
    {
        position: sticky;
        left: 200px;
        z-index: 1;

    }

    .table-head__item:nth-child(3),
    .table-column__item:nth-child(3),
    .grey-box__container:nth-child(3)
    {
        position: sticky;
        left: 440px;
        z-index: 1;

    }

    .table-head__item:nth-child(1),
    .table-head__item:nth-child(2),
    .table-head__item:nth-child(3){
        z-index: 3 !important;
    }

    .box-icon,
    .remove-icon {
        border: 1px solid rgb(23, 70, 162);
        padding: 6px;
        border-radius: 4px;
        cursor: pointer;
    }

    .remove-icon{
        border: 1px solid rgb(255, 62, 62);
    }

    .action-container{
        gap: 8px;
    }

    .table-head__item:last-child,
    .table-column__item:last-child{
        position: sticky ;
        right: 0;
    }


    
    
}