.screen-template__container__mobile {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 21;
    background: white;
    padding: 56px 0 16px; 
    overflow: scroll;
}
