.schedule-detail__container{
    width: 70vw;
    height: 80vh;
    position: relative;
    flex-direction: column;
    background: white;
    padding-bottom: 32px;

    .header{
        padding: 16px;
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid var(--default-grey);

        .title{
            font-size: 14px;
            font-weight: 600;
        }
    }

    
    .body::-webkit-scrollbar {
        width: 4px !important;
        height: 0;
    }
   
    .body::-webkit-scrollbar-thumb {
        border-radius: 16px;
        background-color: var(--main-color);
    }
    
    .body::-webkit-scrollbar-track {
        border-top: 2px solid white;
        border-bottom: 2px solid white;
        background: rgba(181, 176, 176, 0.3);
    }

    .body{
        padding: 16px;
        width: 100%;
        padding-top: 24px;
        height: 100%;
        flex-direction: column;
        overflow: auto;

        button{
            background: var(--main-color);
            border-radius: 2px;
            cursor: pointer;
            height: 28px;
            padding: 7px 12px;
            border-radius: 4px;


            color: #fff;
            font-family: Poppins;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
        }

        .form-field__container{
            margin-bottom: 0 !important;
            max-width: 50vw;
        }

        .label{
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 16px;
        }

        .schedule-information__container{
            width: 100%;
            height: max-content;
            border-bottom: 1px solid #b9b9b9;
            padding: 16px;
          
            .box-info__item{
                display: flex;
                margin-bottom: 4px;
                align-items: flex-start;
                
                .left-text,
                .right-text{
                    font-size: 14px;
                    font-weight: 400;
                    margin-top: 4px;
                    width: 160px;

                    span{
                        font-weight: 500;
                    }
                }

                .divider-text{
                    margin-right: 16px;
                }
    
                .right-text{
                    width: max-content;
                }


                .right-text__wrapper{
                    flex-direction: column;
                }
            }
        }

        
      
     
    
   
        .box-additional{
            // height: calc(100vh - 370px);
            margin-top: 32px;
            padding: 0 16px;

            .box-info__additional{
                .box-item{
                    margin-bottom: 24px;
                    .label-item{
                        font-size: 14px;
                        font-weight: 500;
                        margin-bottom: 16px;
                    }

                    .list-info{
                        margin-left: 16px;
                        .text-item{
                            font-size: 14px;
                            margin-bottom: 4px;
                        }

                        .rich-text__box{
                            margin-left: 24px;
                        }
                    }

                   
                }
            }            
        }
    }

    .footer{
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        padding: 24px 16px;
        position: absolute;
        bottom: 0;
        right: 0;

        button{
            background: var(--main-color);
            border-radius: 2px;
            cursor: pointer;
            height: 28px;
            padding: 7px 12px;
            width: 95px;


            color: #fff;
            font-family: Poppins;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
        }


        .button-cancel{
            background: none;
            border: 1px solid var(--main-color);
            color: var(--main-color);

        }
    }


}