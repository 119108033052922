.mobile-header-with-back__container{
    height: 56px;
    background: white;
    box-shadow: 2px 4px 16px rgba(0,0,0,.05);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0 16px;
    z-index: 20;

    .title{
        font-size: 14px;        
        font-weight: 600;                
        margin-left: 16px;                       
        margin-top: 4px;                                                                                             
    }
    
}