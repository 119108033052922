:root {
    --main-color: rgb(248, 124, 124);

    --main-color-8: rgba(248, 124, 124, 0.8);
    --main-color-7: rgba(248, 124, 124, 0.7);
    --main-color-5: rgba(248, 124, 124, 0.5);
    --main-color-3: rgba(248, 124, 124, 0.3);
    --main-color-1: rgba(248, 124, 124, 0.1);

    --second-color: #ff6060cc;

    --text-color-1: #2d2d2d;

    --default-grey: #e4e4e4;
    --default-black: #2d2d2d;

    --error-color: #ef5350;
}
