.card-event__container{
    background: #cdffe7;
    border: 1px solid #bcd5ce;    
    height: 100%;
    border-radius: 4px;
    padding: 8px;
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    // overflow: hidden;
    cursor: pointer;
    position: relative;
    justify-content: space-between;


    p{
        margin: 0;
    }
    .time-text{
        font-weight: 500;
        font-size: 10px;
        margin-bottom: 4px;
    }

    .host-text{
        font-size: 10px;
        margin-top: 8px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        span{
            font-weight: 600;
        }
    }

    .event-text{
        font-size: 12px;
        font-weight: 600;
        margin-top: 8px;
        position: relative;
        z-index: 2;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

    }

    .studio-text{
        color: var(--main-color);
        font-size: 9px;
        font-weight: bold;
    }


    .account-box{
        font-size: 8px;
        border-radius: 6px;
        padding: 2px 6px 2px 4px;
        display: flex;


        .account-text{
            margin-left: 4px;
            font-weight: 600;
        }
    }

    .section-bottom{
        justify-content: flex-end;

        @media(max-width: 700px ){
            display: none;
        }

        .account-box{
            margin-top: 12px;
            position: relative;
            width: max-content;
            align-self: flex-end;
            .circle-count{
                position: absolute;
                width: 14px;
                height: 14px;
                border-radius: 8px;
                font-size: 7px;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
                top: -6px;
                right: -6px;
                padding-top: 2px;
                color:white
            }
        }
    }

  
}


.card-event__container:hover{
    .add-new{

        @media(max-width: 700px ){
            display: none!important;
        }

        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        background: #9ee9d1;
        height: 32px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        padding: 0 8px;
        width: 100%;
        animation-name: fade-animation;
        animation-duration: 0.5s;

        .text-click{
            font-size: 10px;
            color: black;
            font-weight: 600;
            white-space: nowrap;
            margin-top: 4px;
            margin-left: 4px;
            text-overflow: ellipsis;
            flex: 1;
        }

    }


    @keyframes fade-animation {
        from {width: 0%;}
        to {width: 100%}
      }
}