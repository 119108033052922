.download-template__container{
    position: relative;

    .download-template__button {
        cursor: pointer;
        width: 173px;
        height: 32px;
        border: 1px solid #0066b1;
        border-radius: 2px;
        column-gap: 8px;
        justify-content: center;
        align-items: center;
        background-color: white;

        .title-download {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #0066b1;
        }

    }

    .floating-select-month__container{
        position: absolute;
        top:48px;
        display: flex;
        background: white;
        flex-direction: column;
        width: 232px;
        right: 0;
        padding: 16px;
        border: 1px solid #dddddd;
        border-radius: 4px;

        .react-datepicker-popper[data-placement^=bottom] {
            z-index: 4;
        }

        .react-datepicker__input-container{
            display: flex;
            position: relative;
            border-radius: 4px;
            border: 1px solid grey;
            margin-bottom: 8px;
            z-index: 999;

            svg{
                position: absolute;
                right: 8px;
                top: 4px;
            }

            input{
                flex: 1;
                padding-left: 12px;
                font-size: 12px;
                font-weight: 600;
                color: black;
            }
            
        }

        .download-button{
            height: 28px;
            background: var(--main-color);
            font-size: 12px;
            color: white;
        }
        
    }
}