.section-header__container__schedule {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 700px) {
        margin-bottom: 16px;
    }

    .filter-date__mobile {
        width: 56px;
        display: flex;
        justify-content: flex-end;

        @media (max-width: 700px) {
            width: 56px;

            .chakra-skeleton {
                width: 10px;
            }
        }
    }

    .left-side {
        display: flex;
        align-items: center;

        .day-nav {
            gap: 16px;
            margin-right: 16px;

            @media (max-width: 700px) {
                gap: 8px;
                margin-right: 0;
            }
        }

        .upload-button {
            background: var(--main-color) !important;
            height: 32px;
            font-size: 12px;
            color: white;
        }

        @media (max-width: 700px) {
            width: 100%;
            justify-content: space-between;

            .box-filter {
                .selected-text {
                    display: none;
                }

                .icon {
                    padding: 0;
                    border: none;
                }
            }

            .upload-button {
                display: none;
            }
        }

        .box-icon {
            cursor: pointer;
            width: 32px;
            height: 32px;
            background: var(--main-color);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;

            @media (max-width: 700px) {
                width: 24px;
                height: 24px;
            }
        }

        .date-range-text {
            font-size: 16px;
            font-weight: 600;

            @media (max-width: 700px) {
                font-size: 12px;
            }
        }

        .date-week__picker {
            margin-top: 4px;
            cursor: pointer;
            align-items: center;
            width: max-content;

            .icon-week {
                margin-right: 8px;
            }
        }
    }

    .right-side {
        @media (max-width: 768px) {
            display: none;
        }
        .filter-setting__container {
            justify-content: center;
            align-items: center;
            margin-left: 8px;
            position: relative;

            .popup-container {
                position: absolute;
                height: max-content;
                width: 200px;
                background: white;
                border-radius: 4px;
                border: 1px solid grey;
                z-index: 9;
                right: 0;
                top: 42px;
                padding: 8px 0;
                flex-direction: column;

                .head {
                    padding: 0 12px 8px;
                    border-bottom: 1px solid rgb(209, 209, 209);

                    .title {
                        font-size: 12px;
                        font-weight: 600;
                    }
                }

                .body {
                    padding: 12px;
                    flex-direction: column;

                    .item {
                        justify-content: space-between;
                        width: 100%;
                        padding: 8px 0;
                        // border-bottom: 1px solid rgb(223, 223, 223);

                        .name {
                            font-size: 13px;
                            font-weight: 600;
                        }

                        .box-checkbox {
                            border: 1px solid black;
                            height: max-content;
                        }
                    }
                }

                .footer {
                    width: 100%;
                    gap: 8px;
                    justify-content: flex-end;
                    padding-right: 8px;

                    button {
                        background: var(--main-color);
                        border-radius: 2px;
                        cursor: pointer;
                        height: 24px;
                        padding: 7px 12px;
                        width: max-content;

                        color: #fff;
                        font-family: Poppins;
                        font-size: 9px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 18px;
                    }

                    .cancel-button {
                        background: none;
                        border: 1px solid var(--main-color);
                        color: var(--main-color);
                    }
                }
            }
        }
    }
}

.rs-picker-date-menu .rs-calendar-table-cell-week-number {
    background-color: black;
    color: white;
    padding: 10px;
    font-weight: bold;
}

.rs-picker-date-menu .rs-calendar-table-row:not(.rs-calendar-table-header-row):hover .rs-calendar-table-cell-content {
    background-color: rgba(204, 233, 255, 0.5);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
}

.rs-picker-toolbar {
    display: none !important;
}
