.global-notification__container{
    position: relative;

    .floating-box__notification__container{
        position: absolute;
        width: max-content;
        background: white;
        border:1px solid var(--default-grey);
        top: 48px;
        right: 0;
        border-radius: 6px;
    }

    .notif-num{
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background: var(--main-color);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        font-size: 12px;
        position: absolute;
        top: -8px;
        right: -8px;
    }

    .floating-box__notification__container{
        .item{
            padding: 16px;
            border-bottom: 1px solid var(--default-grey);
            align-items: center;
            cursor: pointer;

            .text{
                font-size: 12px;
                margin-left: 18px;
            }

        }


        .not-active{
            background: #f5f5f5;
            cursor: not-allowed;
            .text{
                color: grey;
            }
        }

      
    }
}